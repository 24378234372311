'use client';

import { Stack, Collapse } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { isAuthenticatedSelector } from '@/src/store/reducers/main';
import { useReduxSelector } from '@/src/store/store';
import { NavbarAuthButtons } from './NavbarAuthButtons';
export const NavbarCallToActionButtons = () => {
  const {
    isSuccess
  } = useGetCurrentUserInfoRequest();
  const isAuthenticated = useReduxSelector(isAuthenticatedSelector);
  const isMobile = useIsMobile();
  const buttons = isAuthenticated ? <NavbarAuthButtons /> : null;
  return <Collapse orientation="horizontal" collapsedSize={50} in={isSuccess} data-sentry-element="Collapse" data-sentry-component="NavbarCallToActionButtons" data-sentry-source-file="NavbarCallToActionButtons.tsx">
      <Stack direction="row" sx={{
      justifyContent: 'end',
      alignItems: 'center',
      gap: [1, 1, 1.5]
    }} data-sentry-element="Stack" data-sentry-source-file="NavbarCallToActionButtons.tsx">
        {!isMobile && isSuccess && buttons}
      </Stack>
    </Collapse>;
};